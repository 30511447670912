import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';

import { appStore } from '../../store/appStore';
import { answerStore } from '../../store/answerStore';
import { getRawHtml, targetYM } from '../../helper';

import { Button } from '../ui/button';

import Star1 from '../../assets/img/star_1.png';

import './answer.scss';

export const Answer = observer(() => {
    const { id } = useParams();

    useEffect(() => {
        answerStore.answerId = id;
        answerStore.getData();
    }, [id])

    useEffect(() => {
        answerStore.getAnswers();
        answerStore.getRandomAnswer();
        // eslint-disable-next-line
    }, [id, answerStore.data.answers[0]])

    return (
        <>
            {(answerStore.answer !== undefined && answerStore.answer !== null) &&
                <Helmet>
                    <title>{answerStore.answer.question_name}</title>
                    <meta name="description" content={`${answerStore.answer.answer}`} />
                </Helmet>
            }
            <section className="section answer">
                <div className="answer__bg">
                    <img src={Star1} alt="" />
                </div>
                <div className="container">
                    <div className="section_inner">
                        {(answerStore.answer !== undefined && answerStore.answer !== null) &&
                            <div className="answer__box">
                                <h2 className="answer__box_title" dangerouslySetInnerHTML={getRawHtml(answerStore.answer.question_name)} />
                                <p className="answer__box_text p4" dangerouslySetInnerHTML={getRawHtml(answerStore.answer.answer)} />
                                <p className="answer__box_descr p5">{answerStore.answer.author}</p>
                                <div className="answer__box_actions">
                                    <Button className="answer__box_btn __secondary __bordo"
                                        onClick={() => {
                                            appStore.show();
                                            targetYM('otvet_drugoi_otvet');
                                            setTimeout(() => {
                                                answerStore.getRandomAnswer();
                                            }, 500)
                                        }}
                                    >
                                        Другой ответ
                                    </Button>
                                    <Button type="copy" />
                                    <Button type="share" path={answerStore.answer.share} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>

    )
})
