import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { answerStore } from '../../store/answerStore';
import { appStore } from '../../store/appStore';
import { targetYM } from '../../helper';

import IconSearch from '../../assets/svg/search.svg';
import QuestionBg from '../../assets/img/question__bg.png';
import Star from '../../assets/img/star_3.png';

import 'swiper/css';
import './questions.scss';

export const Questions = observer(() => {
    const [questions, setQuestion] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        setQuestion(answerStore.questions);
        // eslint-disable-next-line
    }, [answerStore.questions])

    const changeScreen = (id) => {
        appStore.show();

        setTimeout(() => {
            navigate(`/answer/${id}`);
        }, 500);
    }

    return (
        <section className='section questions'>
            <div className="container">
                <div className="section_inner">
                    <div className="questions__bg">
                        <img src={QuestionBg} alt="" />
                    </div>
                    <div className="questions__icon">
                        <img src={Star} alt="" />
                    </div>
                    <p className="questions__title">
                        Выберите вопрос, чтобы сгенерировать ответ
                    </p>
                    {questions !== undefined &&
                        <div className="questions__items">
                            {questions.map(({ id, title }, index) => (
                                <div className="questions__item" key={id}
                                    onClick={() => {
                                        changeScreen(id);
                                        targetYM(`voprosy_${index + 1}`)
                                    }}
                                >
                                    <p className='questions__item_text'>
                                        {title}
                                    </p>
                                    <div className="questions__item_icon">
                                        <img src={IconSearch} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </section>
    )
})
