import { makeAutoObservable } from "mobx";
import { infoStore } from "./infoStore";

class AnswerStore {
  url = '';
  selectId = null;
  answerId = null;
  data = {answers: [], questions: []};
  answers = [];
  questions = [];
  answer = undefined;

  getData = async () => {
    await infoStore.getData();
    this.url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-answers/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-answers/';

    if (+this.answerId === infoStore.data[0].id) {
      this.url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-answers1/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-answers1/';
    } else if (+this.answerId === infoStore.data[1].id) {
      this.url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-answers2/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-answers2/';
    } else if (+this.answerId === infoStore.data[2].id) {
      this.url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-answers3/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-answers3/';
    } else {
      this.url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-answers/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-answers/';
    }

    const res = await fetch(this.url);

    if (res.ok) {
      this.data = await res.json();
    }
  };

  getQuestions = async () => {
    this.url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-answers/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-answers/';

    const res = await fetch(this.url);

    if (res.ok) {
      const { questions } = await res.json();

      this.questions = questions;
    }
  };

  getAnswers = () => {
    if (this.data.length === 0) return;
    this.answers = this.data.answers.filter(
      (i) => i.question_id === +this.answerId
    );
  };

  getRandomAnswer = () => {
    this.answer = this.answers[Math.floor(Math.random() * this.answers.length)];
  };

  copy = () => {
    navigator.clipboard.writeText(this.answer.answer);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const answerStore = new AnswerStore();
