import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { answerStore } from '../../../store/answerStore';
import { share, targetYM } from '../../../helper';

import './button.scss';

export const Button = (props) => {
    const { children, className, link, path, type, onClick = () => { } } = props;
    const [isActive, setIsActive] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        if (!link) return;

        navigate(path);
    }

    if (type === 'copy') {
        return (
            <button className={`btn __copy ${isCopy ? '__active' : ''}`} onClick={() => {
                let timeout;
                clearTimeout(timeout);
                setIsCopy(true);
                answerStore.copy();
                targetYM('otvet_kopirovanie');

                timeout = setTimeout(() => {
                    setIsCopy(false);
                }, 1000)

            }}>
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1581_5223)">
                        <path d="M21.9552 9.98242H12.1603C10.9582 9.98242 9.98364 10.9569 9.98364 12.1591V21.954C9.98364 23.1561 10.9582 24.1306 12.1603 24.1306H21.9552C23.1573 24.1306 24.1319 23.1561 24.1319 21.954V12.1591C24.1319 10.9569 23.1573 9.98242 21.9552 9.98242Z" stroke="#811C3C" strokeWidth="3.02778" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.63045 16.5139H4.54213C3.96484 16.5139 3.41121 16.2846 3.003 15.8764C2.5948 15.4682 2.36548 14.9146 2.36548 14.3373V4.54237C2.36548 3.96509 2.5948 3.41145 3.003 3.00325C3.41121 2.59505 3.96484 2.36572 4.54213 2.36572H14.3371C14.9143 2.36572 15.468 2.59505 15.8762 3.00325C16.2844 3.41145 16.5137 3.96509 16.5137 4.54237V5.6307" stroke="#811C3C" strokeWidth="3.02778" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1581_5223">
                            <rect width="26.1198" height="26.1198" fill="white" transform="translate(0.188721 0.188477)" />
                        </clipPath>
                    </defs>
                </svg>
                <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 18.0002L19 21.0002L29 11.0002" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M28 19.0002V26.0002C28 26.5307 27.7893 27.0394 27.4142 27.4145C27.0391 27.7895 26.5304 28.0002 26 28.0002H12C11.4696 28.0002 10.9609 27.7895 10.5858 27.4145C10.2107 27.0394 10 26.5307 10 26.0002V12.0002C10 11.4698 10.2107 10.9611 10.5858 10.586C10.9609 10.211 11.4696 10.0002 12 10.0002H23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.26497 17.1485H5.17665C4.59937 17.1485 4.04573 16.9191 3.63753 16.5109C3.22932 16.1027 3 15.5491 3 14.9718V5.17689C3 4.59961 3.22932 4.04597 3.63753 3.63777C4.04573 3.22957 4.59937 3.00024 5.17665 3.00024H14.9716C15.5489 3.00024 16.1025 3.22957 16.5107 3.63777C16.9189 4.04597 17.1482 4.59961 17.1482 5.17689V6.26522" stroke="white" strokeWidth="3.02778" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
        )
    } else if (type === 'share') {
        return (
            <div className={`btn __share ${isActive ? '__active' : ''}`}>
                <div className="btn__items">
                    <div className="btn__items_box">
                        <div className="btn__item"
                            onClick={() => {
                                share('tg', path);
                                setIsActive(false);
                                targetYM('otvet_tg');
                            }}
                        >
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1719_17126)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.2659 20.5333C41.2659 31.8736 32.0728 41.0667 20.7326 41.0667C9.39231 41.0667 0.199219 31.8736 0.199219 20.5333C0.199219 9.19309 9.39231 0 20.7326 0C32.0728 0 41.2659 9.19309 41.2659 20.5333ZM21.4698 15.1589C19.4726 15.9896 15.4811 17.709 9.49519 20.3169C8.52318 20.7034 8.014 21.0816 7.96764 21.4513C7.8893 22.0762 8.67184 22.3223 9.73745 22.6574C9.8824 22.7029 10.0326 22.7502 10.1866 22.8002C11.235 23.141 12.6452 23.5397 13.3784 23.5555C14.0434 23.5699 14.7857 23.2957 15.6051 22.733C21.1979 18.9577 24.085 17.0495 24.2662 17.0084C24.3941 16.9794 24.5713 16.9429 24.6914 17.0496C24.8114 17.1563 24.7996 17.3584 24.7869 17.4126C24.7094 17.7431 21.6376 20.5988 20.048 22.0767C19.5524 22.5374 19.2009 22.8642 19.1291 22.9389C18.9681 23.1061 18.804 23.2642 18.6464 23.4162C17.6724 24.3551 16.942 25.0592 18.6868 26.2091C19.5253 26.7616 20.1962 27.2185 20.8656 27.6744C21.5966 28.1722 22.3258 28.6687 23.2692 29.2871C23.5095 29.4447 23.739 29.6083 23.9626 29.7677C24.8134 30.3742 25.5777 30.9191 26.5219 30.8322C27.0706 30.7817 27.6374 30.2658 27.9252 28.7271C28.6054 25.0906 29.9425 17.2115 30.2515 13.9647C30.2786 13.6802 30.2445 13.3161 30.2172 13.1563C30.1898 12.9965 30.1327 12.7688 29.925 12.6003C29.6789 12.4006 29.2992 12.3585 29.1293 12.3615C28.357 12.3751 27.1721 12.7871 21.4698 15.1589Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1719_17126">
                                        <rect width="41.0667" height="41.0667" fill="white" transform="translate(0.199219)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="btn__item"
                            onClick={() => {
                                share('vk', path);
                                setIsActive(false);
                                targetYM('otvet_vk');
                            }}
                        >
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1918_124)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.08597 3.08695C0.199219 5.97371 0.199219 10.6198 0.199219 19.9122V21.5549C0.199219 30.8472 0.199219 35.4933 3.08597 38.3801C5.97273 41.2669 10.6189 41.2669 19.9112 41.2669H21.5539C30.8462 41.2669 35.4923 41.2669 38.3792 38.3801C41.2659 35.4933 41.2659 30.8472 41.2659 21.5549V19.9122C41.2659 10.6198 41.2659 5.97371 38.3792 3.08695C35.4923 0.200195 30.8462 0.200195 21.5539 0.200195H19.9112C10.6189 0.200195 5.97273 0.200195 3.08597 3.08695ZM7.12932 12.6914C7.35177 23.3687 12.6904 29.7854 22.0502 29.7854H22.5807V23.6767C26.0201 24.0189 28.6208 26.5343 29.6646 29.7854H34.5242C33.1895 24.9258 29.6816 22.2394 27.4914 21.2127C29.6816 19.9465 32.7616 16.8665 33.4974 12.6914H29.0826C28.1244 16.0794 25.2843 19.1594 22.5807 19.4503V12.6914H18.1659V24.5323C15.4281 23.8478 11.9717 20.5283 11.8177 12.6914H7.12932Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1918_124">
                                        <rect width="41.0667" height="41.0667" fill="white" transform="translate(0.199219 0.200195)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="btn__trigger" onClick={() => {
                    setIsActive(!isActive);
                    targetYM('otvet_share');
                }}>
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1581_5241)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.31879 23.1629C8.28589 23.1629 9.06988 22.3789 9.06988 21.4118C9.06988 20.4447 8.28589 19.6607 7.31879 19.6607C6.35169 19.6607 5.56771 20.4447 5.56771 21.4118C5.56771 22.3789 6.35169 23.1629 7.31879 23.1629ZM12.0977 21.4118C12.0977 24.0511 9.95809 26.1907 7.31879 26.1907C4.6795 26.1907 2.53993 24.0511 2.53993 21.4118C2.53993 18.7725 4.6795 16.6329 7.31879 16.6329C9.95809 16.6329 12.0977 18.7725 12.0977 21.4118Z" fill="#811C3C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.3789 15.5442C21.3459 15.5442 22.1299 14.7603 22.1299 13.7932C22.1299 12.8261 21.3459 12.0421 20.3789 12.0421C19.4118 12.0421 18.6278 12.8261 18.6278 13.7932C18.6278 14.7603 19.4118 15.5442 20.3789 15.5442ZM25.1577 13.7932C25.1577 16.4325 23.0181 18.572 20.3789 18.572C17.7396 18.572 15.6 16.4325 15.6 13.7932C15.6 11.1539 17.7396 9.01429 20.3789 9.01429C23.0181 9.01429 25.1577 11.1539 25.1577 13.7932Z" fill="#811C3C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.31879 7.92584C8.28589 7.92584 9.06988 7.14185 9.06988 6.17475C9.06988 5.20765 8.28589 4.42366 7.31879 4.42366C6.35169 4.42366 5.56771 5.20765 5.56771 6.17475C5.56771 7.14185 6.35169 7.92584 7.31879 7.92584ZM12.0977 6.17475C12.0977 8.81404 9.95809 10.9536 7.31879 10.9536C4.6795 10.9536 2.53993 8.81404 2.53993 6.17475C2.53993 3.53546 4.6795 1.39589 7.31879 1.39589C9.95809 1.39589 12.0977 3.53546 12.0977 6.17475Z" fill="#811C3C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.8681 12.9121C18.4471 13.6345 17.5203 13.8789 16.7979 13.4579L9.36462 9.12641C8.64222 8.70545 8.39786 7.77858 8.81881 7.05618C9.23977 6.33379 10.1666 6.08942 10.889 6.51038L18.3223 10.8419C19.0447 11.2629 19.2891 12.1897 18.8681 12.9121Z" fill="#811C3C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.83018 20.5316C8.40876 19.8095 8.65254 18.8825 9.37467 18.4611L16.797 14.1295C17.5192 13.7081 18.4462 13.9519 18.8676 14.674C19.289 15.3961 19.0453 16.3232 18.3231 16.7446L10.9008 21.0761C10.1786 21.4975 9.2516 21.2537 8.83018 20.5316Z" fill="#811C3C" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1581_5241">
                                <rect width="26.1198" height="26.1198" fill="white" transform="matrix(-1 0 0 -1 26.9087 26.854)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        )
    } else {
        return (
            <button className={`btn ${classNames(className)}`}
                onClick={() => {
                    handleClick();
                    onClick();
                }}
            >
                {children}
            </button>
        )
    }
}
