import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { menuStore } from '../../store/menuStore';
import { appStore } from '../../store/appStore';
import { targetYM } from '../../helper';

import Cross from '../../assets/svg/cross.svg';
import Logo from '../../assets/svg/logo_1.svg';

import './aside.scss';

export const Aside = observer(() => {
  const navigate = useNavigate();

  return (
    <aside className={`aside ${menuStore.isActive ? '__active' : ''}`}>
      <div className="aside__shadow" onClick={() => menuStore.toggleMenu()}></div>
      <div className="aside_inner">
        <div className="aside__head">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="close" onClick={() => menuStore.toggleMenu()}>
            <img src={Cross} alt="" />
          </div>
        </div>

        <nav className="nav">
          <ul className='nav__list'>
            <li className='nav__list_item'
              onClick={() => {
                menuStore.toggleMenu();
                navigate('/');
                targetYM('burger_glavnaya');
              }}
            >
              <p className='h3' >На главную</p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.2241 10.528L18.5921 9.16L26.0561 16.624L18.5921 24.04L17.2241 22.672L22.3121 17.584H10.2401V22.6H8.29613V10.6H10.2401V15.616H22.3121L17.2241 10.528Z" fill="white" />
              </svg>
            </li>
            <li className='nav__list_item'
              onClick={() => {
                menuStore.toggleMenu();
                appStore.changeBlock = true;

                setTimeout(() => {
                  navigate('/questions');
                  appStore.changeBlock = false;
                  targetYM('burger_poluchit_otvet');
                }, 500);
              }}
            >
              <p className='h3'>Вопросы, от&nbsp;которых аж&nbsp;трясет</p>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.2241 10.528L18.5921 9.16L26.0561 16.624L18.5921 24.04L17.2241 22.672L22.3121 17.584H10.2401V22.6H8.29613V10.6H10.2401V15.616H22.3121L17.2241 10.528Z" fill="white" />
              </svg>
            </li>
            <li className='nav__list_item'>
              <Link className='h3' to="https://achbd.media/ACHBD_for_advertisers.pdf" target='_blank'>
                Рекламодателям
              </Link>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2241 10.528L18.5921 9.16L26.0561 16.624L18.5921 24.04L17.2241 22.672L22.3121 17.584H10.2401V22.6H8.29613V10.6H10.2401V15.616H22.3121L17.2241 10.528Z" fill="white" />
                </svg>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  )
})
