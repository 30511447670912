import { Header } from '../../components/header';
import { Aside } from '../../components/aside';
import { Footer } from '../../components/footer/infex';
import { Answer } from '../../components/answer';

export const AnswerPage = () => {
  return (
    <div className='__site_wrapper __bg_3'>
      <Header />
      <Aside />
      <main className='main'>
          <Answer />
      </main>
      <Footer />
    </div>
  )
}
