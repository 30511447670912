export function targetYM(txt) {
    if (typeof window.ym === 'function') window.ym(94903340, 'reachGoal', txt);
}

export function getRawHtml(txt) {
    return {__html: txt}
}

export function share(social, path) {
    const socials = {
      vk: "https://vk.com/share.php?url=",
      ok: "https://connect.ok.ru/offer?url=",
      in: "https://www.linkedin.com/shareArticle?mini=true&url=",
      tg: "https://telegram.me/share/url?url=",
      fb: "https://www.facebook.com/sharer/sharer.php?u=",
      tw: "https://twitter.com/intent/tweet?url=",
    };
    let url = encodeURIComponent(path);
    let shareUrl = `${socials[social]}${url}`;
  
    window.open(shareUrl, "", "toolbar=0,status=0,width=800,height=500");
  }
  