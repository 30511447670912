import Lottie from 'lottie-react'
import { observer } from 'mobx-react-lite';

import { appStore } from '../../store/appStore';

import loaderDesk from '../../assets/lottie/loader_desk.json';
import loaderDeskMin from '../../assets/lottie/loader_desk_min.json';
import loaderTablet from '../../assets/lottie/loader_tablet.json';
import loaderMobile from '../../assets/lottie/loader_mob.json';

import './loader.scss';

export const Loader = observer(() => {
    const { isVisible } = appStore

    return (
        <div className={`loader ${isVisible ? '__active' : ''}`}>
            <div className="loader__lottie">
                <Lottie animationData={loaderDesk} loop={true} />
            </div>
            <div className="loader__lottie">
                <Lottie animationData={loaderDeskMin} loop={true} />
            </div>
            <div className="loader__lottie">
                <Lottie animationData={loaderTablet} loop={true} />
            </div>
            <div className="loader__lottie">
                <Lottie animationData={loaderMobile} loop={true} />
            </div>
        </div>
    )
})
