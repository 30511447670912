import { makeAutoObservable } from "mobx";

class FooterStore {
  url = window.location.href.includes('spans.dev') ? "https://achbd-gen-api.spans.dev/wp-json/wp/v2/get-footer-menu/" : 'https://api-generator.achbd.media/wp-json/wp/v2/get-footer-menu/';

  data = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getData() {
    const res = await fetch(this.url);

    if (res.ok) {
      this.data = await res.json();
    }
  }
}

export const footerStore = new FooterStore();
