import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { answerStore } from "./store/answerStore";

import { HomePage } from "./pages/homePage";
import { InfoPage } from "./pages/infoPage";
import { QuestionsPage } from "./pages/questionsPage";
import { AnswerPage } from "./pages/answerPage";
import { NotFoundPage } from "./pages/notFoundPage";
import { Loader } from "./components/loader";

export const App = observer(() => {
  useEffect(() => {
    answerStore.getQuestions();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/answer/:id" element={<AnswerPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Loader />
    </>
  );
});
